<template>
  <div>
    <CRow v-if="view == 'table'">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de creditos"
          icon="fas fa-users"
          btn_name="cliente"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'7%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_add="mtd_add"
          @mtd_date="mtd_date"
          @mtdChangeStatus="mtdChangeStatus"
        />
      </CCol>
    </CRow>
    <CRow v-else-if="view == 'add'">
      <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-right">
        <CButton
          size="sm"
          color="info"
          @click="mtd_save"
          :disabled="cp_disabled_save"
        >
          <i class="fas fa-save"></i> Registrar
        </CButton>
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" class="mt-3">
        <CCard>
          <CCardHeader>
            <strong> DETALLE</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xxl="5" lg="12" md="12" sm="12" xs="12">
                <CInput          
                  label="Producto"
                  placeholder="Producto"
                  v-model="item_det.name_product"
                  :disabled="select"
                  @keyup="mtd_filter_product"
                ></CInput>
              </CCol>
              <CCol xxl="2" lg="3" md="12" sm="12" xs="12">
                <CInput          
                  label="Precio"
                  placeholder="Precio"
                  v-model="item_det.price"
                  v-on:keypress="isNumber($event)"
                ></CInput>
              </CCol>
              <CCol xxl="2" lg="3" md="12" sm="12" xs="12">
                <CInput          
                  label="Cantidad"
                  placeholder="Cantidad"
                  v-model="item_det.quantity"
                  v-on:keypress="isNumber($event)"
                ></CInput>
              </CCol>
              <CCol xxl="2" lg="3" md="12" sm="12" xs="12">
                <CInput          
                  label="Subtotal"
                  placeholder="Sub total"
                  v-model="cp_subtotal"
                  :disabled="true"
                ></CInput>
              </CCol>
              <CCol xxl="1" lg="3" md="12" sm="12" xs="12">
                <!-- <label for=""></label>
                <br> -->
                <CButton size="sm" class="mt-4 mb-1 form-control btn-info-dev" color="info" :disabled="cp_add_item" @click="mtd_add_item">
                  <i class="fas fa-plus"></i>
                </CButton>
              </CCol>
              <CCol lg="12" md="12" sm="12" xs="12" v-if="data_product_filter.length > 0">
                <CRow>
                  <CBadge v-for="(item, index) in data_product_filter" @click="mtd_select_prd(item)" :key="index" color="info" class="p-1 pointer ml-1 mb-1">{{item.label}}</CBadge>
                </CRow>
              </CCol>
              <CCol lg="12" md="12" sm="12" xs="12" v-if="sale.detail.length > 0">
                <div class="position-relative table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>PRODUCTO</th>
                        <th>CANTIDAD</th>
                        <th>PRECIO</th>
                        <th>SUBTOTAL</th>
                        <th>ACCIÓN</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in sale.detail" :key="index">
                        <td>{{index + 1}}</td>
                        <td>{{item.name_product}}</td>
                        <td>{{item.quantity}}</td>
                        <td>{{item.price}}</td>
                        <td>{{parseFloat(item.price * item.quantity).toFixed(2)}}</td>
                        <td>
                          <CButton
                            size="sm"
                            color="danger"
                            v-c-tooltip.hover="{
                              content: 'Eliminar',
                            }"
                            @click="mtd_del_item(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="4" class="text-right">TOTAL</th>
                        <th colspan="2" class="text-left"> {{cp_total}}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <!-- <CCol lg="4" md="4" sm="12" xs="12" class="mt-3">
        <CCard>
          <CCardHeader>
            <strong> SUGERENCIAS</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CBadge v-for="(item, index) in data_product_filter" @click="mtd_select_prd(item)" :key="index" color="info" class="p-1 pointer ml-1 mb-1">{{item.label}}</CBadge>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol> -->
    </CRow>
    <CRow v-else>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
        <CButton size="sm" color="warning" @click="mtd_return_table">
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-right">
        <CButton
          size="sm"
          color="info"
          @click="mtd_save_date"
          :disabled="cp_disabled_date"
        >
          <i class="fas fa-edit"></i> Actualizar fecha
        </CButton>
      </CCol>
      <CCol lg="12" md="12" sm="12" xs="12" class="mt-3">
        <CCard>
          <CCardHeader>
            <strong> NUEVA FECHA</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xxl="5" lg="12" md="12" sm="12" xs="12">
                <CInput   
                  type="date"       
                  v-model="date.new_date"
                  label="Nueva fecha de pago"
                ></CInput>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "document", label: "Documento", _style: "width:8%;" },
  { key: "names", label: "Nombres", _style: "width:20%;" },
  { key: "date", label: "Vence en", _style: "width:6%;" },
  { key: "total", label: "Total", _style: "width:6%;" },
  { key: "amount", label: "Pagado", _style: "width:6%;" },
  { key: "pending", label: "Pendiente", _style: "width:6%;" },
  { key:'status', label:'Estado', _style:'width:5%;'}
];

import { mapActions } from "vuex";
import { bus } from "../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CTableWrapper from "../../components/shared/datatable/Table.vue";
export default {
  components: { vSelect, CTableWrapper },
  name: "v-proccess-sale",
  data() {
    return {
      prefix: "sale",
      view: "table",
      fields,
      data: [],
      data_client: [],
      data_product: [],
      data_product_filter:[],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar productos",
          action: "mtd_add",
          icon: "fas fa-plus",
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Cambiar fecha",
          action: "mtd_date",
          icon: "fas fa-calendar",
        },
        {
          class: "btn-sm",
          color: "warning",
          tooltip: "Cambiar estado",
          action: "mtdChangeStatus",
          icon: "fas fa-recycle",
        },
      ],
      /** add */
      select:false,
      sale: {
        credit_id: null,
        detail: [],
      },
      item_det: {
        name_product:"",
        id_producto:"",
        quantity: "",
        price: "",
      },
      /** date */
      date:{
        credit_id:null,
        new_date:null,
      }
      
    };
  },
  created() {
    this.mtd_getData();
  },
  computed: {
    cp_disabled_save: function () {
      if (this.sale.detail.length > 0) return false;
      return true;
    },
    cp_subtotal: function(){
      if (this.item_det.quantity > 0 && this.item_det.price > 0 ) return parseFloat(this.item_det.quantity * this.item_det.price).toFixed(2);
      return 0.00;
    },
    cp_add_item: function(){
      if(this.item_det.name_product.length > 0 && this.item_det.quantity > 0 && this.item_det.price > 0) return false;
      return true;
    },
    cp_total: function(){
      if (this.sale.detail.length>0) {
        let tot = 0;
        this.sale.detail.forEach(element => {
          tot += parseFloat(element.price * element.quantity);
        });
        return tot.toFixed(2);
      }
      return 0.00;
    },
    cp_disabled_date:function(){
      if(this.date.new_date != null) return false;
      return true;
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
          this.data_product = response.data_product;
          this.data_product_filter = []; //response.data_product;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_return_table: function () {
      this.view = "table";
      this.sale= {
        credit_id: null,
        detail: [],
      };
      this.item_det = {
        name_product:"",
        id_producto:"",
        quantity: "",
        price: "",
      };
      this.data_product_filter=this.data_product;
      this.date.new_date = null;
      this.date.credit_id=null;
    },
    mtd_add: function (item) {
       if (item.status == 'Activo') {
        this.view = "add";
        this.sale.credit_id =item.Id;
        this.data_product_filter= []; //this.data_product;
       }else{
        bus.$emit("alert", {
          color: "warning",
          message: "ESTE CLIENTE NO SE ENCUENTRA HABILITADO",
        });
       }
        
    },
    mtd_filter_product:function(){
      if (this.item_det.name_product.length > 0 && this.item_det.name_product != "") {
        let q = this.item_det.name_product.toString().toUpperCase();
        this.data_product_filter=[];
        this.data_product.forEach(element => {
          let name = (element.label.toString());
          // console.log(name,q,name.includes(q));
          if (name.includes(q)) {
            this.data_product_filter.push(element);
          }
          // let name = JSON.stringify(element.label);
          // if (name.indexOf(q)==0) {
          //   this.data_product_filter.push(element);
          // }
        });
      }else{
        this.data_product_filter = []; //this.data_product;
      }

    },
    mtd_add_item: function(){
      this.sale.detail.push(this.item_det);
      this.item_det = {
        name_product:"",
        id_producto:0,
        quantity: "",
        price: "",
      };
      bus.$emit("alert", {
        color: "info",
        message: "ITEM AGREGADO!!",
      });
      this.select =false;
      this.data_product_filter=[];
    },
    mtd_del_item: function (pos) {
      let temp = [];
      this.sale.detail.forEach((element, index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.sale.detail = temp;
    },
    mtd_select_prd: function(col){
      this.item_det.name_product=col.label;
      this.item_det.id_producto=col.value;
      this.item_det.price=col.price
      this.select=true;
    },
    mtd_save: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/store",
        token: this.$store.getters.get__token,
        params: this.sale,
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "VENTA REGISTRADA CORRECTAMENTE!!",
            });
            
            this.data.forEach(element => {
              if (element.Id == this.sale.credit_id) {
                element.total = parseFloat(response.sale.before).toFixed(2);
                element.pending = parseFloat(response.sale.before).toFixed(2);
              }
            });
            this.mtd_return_table();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** up date */
    mtd_date: function (item) {
      this.view='view';
      this.date.credit_id =item.Id;
    },
    mtd_save_date: function(){
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/update",
        token: this.$store.getters.get__token,
        params: this.date,
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "FECHA ACTUALIZADA CORRECTAMENTE!!",
            });
            
            this.data.forEach(element => {
              if (element.Id == this.date.credit_id) {
                element.date = response.date;
              }
            });
            this.mtd_return_table();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** cambiar estado */
    mtdChangeStatus: function(item){
      console.log(item);
      let newState = item.status == 'Activo'?0:1;
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/change",
        token: this.$store.getters.get__token,
        params: {
          id:item.Id,
          state: newState
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "EDITADO CORRECTAMENTE!!",
            });
            this.data.forEach(element => {
              if (element.Id == item.Id) {
                console.log('aa');
                element.status= item.status=='Activo'?'Inactivo':'Activo';
              }
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer{
    cursor: pointer;
}
.btn-info-dev{
  color: #fff;
  background-color: #39f;
}
</style>